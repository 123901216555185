*{
    margin:0;
    padding: 0;
}

html, body{
    background-image: url("../Img/background.png");
    background-position: right bottom;
    background-size: auto;
    background-repeat: repeat;
    background-attachment: scroll;
    font-size: 18px;
}
.Navegacion{
    width: 100vw;
    line-height: 103px;
    background-color: white;
    padding: 10px 0;
    text-align: right;
    font-size: 1.5rem;/*esta es una unidad relativa que toma el tamaño de la letra 
    del elemento raiz por ejm en nuestro caso se declaro en el app.css en el html*/    
}
.My-ul li{
    list-style-type: none;
    display: inline-block;
    padding: 0 10px;
}

.My-ul li a{
    text-decoration: none;
    font-weight: bold;
    color: rgb(247, 94, 37);
}

.My-ul a.is-active{
    font-weight: bold;
    color:#000;
    pointer-events: none;

}
/* #submenu.Submenu{
    display: none;
    padding-left: 0px;
    position: absolute;
    font-size: 16px;
    width: 80px;
    height:0px;
    line-height: 20px;
} */
.submenu:hover .children{
    display: block;
}

.children{
    /* display: none; */
    position:absolute;
    width: 5%;
    z-index: 1000;
}

.children li{
    display: block;
}
