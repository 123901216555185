.bg-white{
    background-color: #fff !important;
}
.card{
    border: 0px solid rgba(255,255,255,0.1);
    border-radius: 0px;
}
.img-products{
    width: 15em;
    height: 8em;
}

.img-product-detail{
    width: 14em;
    height: 10em;
    
}
/*Imagen de la transpaleta sola*/
.img-product-detail-content{
    display: flex;
    justify-content: center;
    align-items: center;
}

/*Imagen de un producto de forma vertical*/
.img-product-detail-h{
    width: 10em;
    height: 14em;
    
}
/*Content de un producto de forma vertical*/
.img-product-detail-content-h{
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color:lightcoral; */
}
.img-product-detail-content-h figcaption{
    /* background-color: lawngreen; */
    position: absolute;
    left: 0em;
    bottom: 0em;
    display: flex;
    width: 30%;
}

/*Imagen de un producto de forma horizontal*/
.img-product-detail-w{
    width: 14em;
    height: 9em;
    
}
/*Content de un producto de forma horizontal*/
.img-product-detail-content-w{
    display: flex;
    justify-content: center;
    align-items: center;
}

/*Content de un producto de forma normal*/
.img-product-detail-content-n{
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn-details{
    /* background-color: greenyellow; */
}

.card-unique{
    align-items: center;
}

/* @media screen and (max-width:42em){
 .img-company{
     width: 14em;
     height: 4em;
 }

    .main-page{
     display: flex;
 }
 .my-card{
     row-gap: 1;
     display: flex;
     flex-wrap: wrap;
     justify-content: space-between;
 }
 .btn-details{
    margin-left:4.5em;
}
} */
@media (min-width:200px) and (max-width:254px){
    .btn-details{
        width: 100%;
        position: relative;
        display: flex;
        justify-content: flex-start;
    }
    .img-products{
        width: 9em;
        height: 5em;
    }
    .img-company{
        width: 6em;
        height: 3em;
    }
    .img-product-detail{
        width: 7em;
        height: 5em;
    }
    /*Imagen de un producto de forma horizontal*/
    .img-product-detail-w{
        width: 10em;
        height: 7em;
        
    }
    /*Content de un producto de forma horizontal*/
    .img-product-detail-content-w{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .img-product-detail-content-h{
        display: flex;
        justify-content: center;
        align-items: center;
        /* background-color:lightcoral; */
        width: 100%;
        padding-bottom: 5em;
    }
    .img-product-detail-content-h figcaption{
        width: 100%;
        /* background-color: lawngreen; */
        display: flex;
        position: absolute;
        left: 0em;
        bottom: 30.1em;
    }
    .img-product-detail-content-h strong{
        font-size: 0.9rem;
    }
}


@media (min-width:255px) and (max-width:319px){
    .btn-details{
        width: 100%;
        position: relative;
        display: flex;
        justify-content: flex-start;
    }
    .img-products{
        width: 12em;
        height: 7em;
    }
    .img-company{
        width: 8em;
        height: 4em;
    }
    .img-product-detail{
        width: 9em;
        height: 7em;
    }
    /*Imagen de un producto de forma horizontal*/
    .img-product-detail-w{
        width: 10em;
        height: 7em;
        
    }
    /*Content de un producto de forma horizontal*/
    .img-product-detail-content-w{
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .img-product-detail-content-h{
        display: flex;
        justify-content: center;
        align-items: center;
        /* background-color:lightcoral; */
        width: 100%;
        padding-bottom: 5em;
    }
    .img-product-detail-content-h figcaption{
        width: 100%;
        /* background-color: lawngreen; */
        display: flex;
        position: absolute;
        left: 0em;
        bottom: 30.1em;
    }
    .img-product-detail-content-h strong{
        font-size: 0.9rem;
    }
}

@media (min-width:320px) and (max-width:480px){
    .btn-details{
        width: 100%;
        position: relative;
        justify-content: flex-start;
        display: flex;
    }
    .img-company{
        width: 12em;
        height: 4em;
    }
    .img-product-detail-content-h{
        display: flex;
        justify-content: center;
        align-items: center;
        /* background-color:lightcoral; */
        width: 100%;
        padding-bottom: 4em;
    }
    .img-product-detail-content-h figcaption{
        width: 100%;
        /* background-color: lawngreen; */
        display: flex;
        position: absolute;
        left: 0em;
        bottom: 30.1em;
    }
    .img-product-detail-content-h strong{
        font-size: 0.9rem;
    }
}

@media (min-width:480px) and (max-width:520px){
    .btn-details{
        display: flex;
        width: 100%;
        justify-content: flex-start;
        position: relative;
    }
    .img-company{
        width: 12em;
        height: 4em;
    }
    .img-product-detail-content-h{
        display: flex;
        justify-content: center;
        align-items: center;
        /* background-color:lightcoral; */
        width: 100%;
        padding-bottom: 4em;
    }
    .img-product-detail-content-h figcaption{
        width: 100%;
        /* background-color: lawngreen; */
        display: flex;
        position: absolute;
        left: 0em;
        bottom: 28.1em;
    }
    .img-product-detail-content-h strong{
        font-size: 0.9rem;
    }
}

@media (min-width:381px) and (max-width:469px) {
    .card-unique{
        align-items: center;
    }
    .btn-details{
        display: flex;
        justify-content: flex-start;
        width: 100%;
        padding-left: 0;
        position: relative;
    }
    .img-company{
        width: 12em;
        height: 4em;
    }
    .img-product-detail-content-h{
        display: flex;
        justify-content: center;
        align-items: center;
        /* background-color:lightcoral; */
        width: 100%;
        padding-bottom: 4em;
    }
    .img-product-detail-content-h figcaption{
        width: 100%;
        /* background-color: lawngreen; */
        display: flex;
        position: absolute;
        left: 0em;
        bottom: 30.1em;
    }
    .img-product-detail-content-h strong{
        font-size: 0.9rem;
    }
}

/* @media (min-width:470px) and (max-width:520px) {
    .btn-details{
        display: flex;
        width: 80%;
        position: relative;
        padding-left: 20%;        
    }
    .img-company{
        width: 12em;
        height: 4em;
    }
} */


@media (min-width:521px) and (max-width:620px){
    .btn-details{
        display: flex;
        justify-content: flex-start;
        width: 100%;
        position: relative;
    }
    .img-company{
        width: 12em;
        height: 4em;
    }
    .img-product-detail-content-h{
        display: flex;
        justify-content: center;
        align-items: center;
        /* background-color:lightcoral; */
        width: 100%;
        padding-bottom: 4em;
    }
    .img-product-detail-content-h figcaption{
        width: 100%;
        /* background-color: lawngreen; */
        display: flex;
        position: absolute;
        left: 0em;
        bottom: 25.1em;
    }
    .img-product-detail-content-h strong{
        font-size: 0.9rem;
    }

}
@media (min-width:621px) and (max-width:991px){
    .img-product-detail-content-h{
        display: flex;
        justify-content: center;
        align-items: center;
        /* background-color:lightcoral; */
        width: 100%;
        padding-bottom: 4em;
    }
    .img-product-detail-content-h figcaption{
        width: 100%;
        /* background-color: lawngreen; */
        display: flex;
        position: absolute;
        left: 0em;
        bottom: 25.1em;
        text-align: center;
    }
    .img-product-detail-content-h strong{
        font-size: 0.9rem;
    }
}

